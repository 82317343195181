/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);

@images-path:"../images/";
@font-path:"../fonts";


@font-face {
    font-family: 'thesans-bold';
    src: url('@{font-path}/thesanbolpla_0-webfont.eot');
    src: url('@{font-path}/thesanbolpla_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesanbolpla_0-webfont.woff2') format('woff2'),
         url('@{font-path}/thesanbolpla_0-webfont.woff') format('woff'),
         url('@{font-path}/thesanbolpla_0-webfont.ttf') format('truetype'),
         url('@{font-path}/thesanbolpla_0-webfont.svg#thesanbolpla_0') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'thesans-black';
    src: url('@{font-path}/thesansblack-webfont.eot');
    src: url('@{font-path}/thesansblack-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesansblack-webfont.woff2') format('woff2'),
         url('@{font-path}/thesansblack-webfont.woff') format('woff'),
         url('@{font-path}/thesansblack-webfont.ttf') format('truetype'),
         url('@{font-path}/thesansblack-webfont.svg#thesansblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'thesans-extrabold';
    src: url('@{font-path}/thesansextrabold-webfont.eot');
    src: url('@{font-path}/thesansextrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesansextrabold-webfont.woff2') format('woff2'),
         url('@{font-path}/thesansextrabold-webfont.woff') format('woff'),
         url('@{font-path}/thesansextrabold-webfont.ttf') format('truetype'),
         url('@{font-path}/thesansextrabold-webfont.svg#thesansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'thesans-extralight';
    src: url('@{font-path}/thesansextralight-webfont.eot');
    src: url('@{font-path}/thesansextralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesansextralight-webfont.woff2') format('woff2'),
         url('@{font-path}/thesansextralight-webfont.woff') format('woff'),
         url('@{font-path}/thesansextralight-webfont.ttf') format('truetype'),
         url('@{font-path}/thesansextralight-webfont.svg#thesansextralight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'thesans-light';
    src: url('@{font-path}/thesanslight-webfont.eot');
    src: url('@{font-path}/thesanslight-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesanslight-webfont.woff2') format('woff2'),
         url('@{font-path}/thesanslight-webfont.woff') format('woff'),
         url('@{font-path}/thesanslight-webfont.ttf') format('truetype'),
         url('@{font-path}/thesanslight-webfont.svg#thesanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'thesans-plain';
    src: url('@{font-path}/thesansplain-webfont.eot');
    src: url('@{font-path}/thesansplain-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/thesansplain-webfont.woff2') format('woff2'),
         url('@{font-path}/thesansplain-webfont.woff') format('woff'),
         url('@{font-path}/thesansplain-webfont.ttf') format('truetype'),
         url('@{font-path}/thesansplain-webfont.svg#thesansplain') format('svg');
    font-weight: normal;
    font-style: normal;
}
//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-success,
.btn-info,
.btn-warning
{
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

  .badge {
    text-shadow: none;
  }
}

.btn-primary, .btn-success{
	background-image: none;
	font-size:17px;
	font-family: @font-family-sans-serif;
	border-radius:35px;
	transition:@transition-time;
	&:hover, &:focus, &:active, &:active:hover, &:active:focus{
		background:none;
		border:1px solid @orange;
		color:@orange;
		box-shadow: none;
	}
}

.btn-danger{
	border-radius:35px;
	box-shaddow:none;
	background:@brand-danger;
	transition:@transition-time;
	&:hover{
		border-color:@brand-danger;
		color:@brand-danger;
		background:none;
	}
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
  #gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  background-repeat: repeat-x;
  border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
    background-color: darken(@btn-color, 12%);
    background-position: 0 -15px;
  }

  &:active,
  &.active {
    background-color: darken(@btn-color, 12%);
    border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken(@btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    background-image: none;
  }
}

body{
	color:@black;
	/*background:#f5f5f5;*/
}

.front .node-ads-actualite .date {
    display:none;
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg); text-shadow: 0 1px 0 #fff; border-color: #ccc; }

.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }



//
// Cookies
// --------------------------------------------------
#sliding-popup.sliding-popup-top{
	position:absolute;
}
#sliding-popup .popup-content #popup-text{
	@media(max-width:@screen-sm-max){
		max-width: 100%;
	}
	@media(min-width:@screen-md-min){
		max-width: 50%;
	}
}
#sliding-popup .popup-content #popup-buttons{
	@media(max-width:@screen-sm-max){
		max-width: 100%;
		text-align:center;
		float:none;
		margin-bottom: 15px;
	}
	@media(min-width:@screen-md-min){
		max-width: 50%;
	}
}
#sliding-popup .popup-content #popup-buttons button{
	margin:10px 0;
	box-shadow: none;
	background:@blue;
	border-radius:0;
	border:1px solid @blue;
	font-family: @font-family-sans-serif;
	font-weight: normal;
	font-size:14px;
	color:@white;
	text-shadow: none;
	transition: @transition-time;
	&:hover, &:active{
		background:none;
		border:1px solid @white;
		color:@white;
		position:static;
	}
	&.find-more-button{
		background:none;
		border:none;
		&:hover{
			text-decoration:underline;
		}
	}

}
#sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text p{
	margin:3px 0 0 0;
	font-family: @font-family-sans-serif;
	font-size:13px;
	font-weight:normal;
}

//
// Images
// --------------------------------------------------

.thumbnail, .img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// btn
// --------------------------------------------------




//
// Jumbotron
// --------------------------------------------------


.container .jumbotron, .container-fluid .jumbotron{
	padding:0;
}


//
// Pagination
// --------------------------------------------------

.pagination > li > a, .pagination > li > span{
	border-radius:100%;
	margin-left:5px;
	margin-right:5px;
	border:none;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
	border-radius: 100%;
    border-radius: 100%;
	background:@blue;
	color:@white;
	border:none;
}

.pagination > li > a:hover, .pagination > li.active > a, .pagination > li.active > span {
	background:@orange;
	color:@white;
}

.pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
	background:@orange;
	color:@white;
}

.pagination > li > a, .pagination > li > span{
	background:@gray-light;
	color:@orange;
}

//
// Formulaires
// --------------------------------------------------

.form-control{
	color:@gray-base;
	background:@gray-form;
	border-radius:0;
	border: none;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2) inset, 0 -1px 0 rgba(0,0,0,0.01) inset;
	height:100%;
	&:focus{
		box-shadow: 0 1px 0 fade(@orange,10%) inset, 0 -1px 0 fade(@orange,10%) inset, 1px 0 0 fade(@orange,10%) inset, -1px 0 0 fade(@orange,10%) inset, 0 0 2px fade(@orange,100%);
		outline: none;
	}
}

.chosen-container-multi{
	width: 100%;
    margin: 0;
    padding: 0;
	.chosen-choices{
		height:100%;
		border:none;
		background:none;
		box-shadow:none;
		padding:8px 0 0 15px;
		li.search-choice{
			margin:0 5px 5px 0;
		}
	}
}

.form-control[disabled], fieldset[disabled] .form-control{
	color:@gray-base;
	background:#dcdcdc;
	border-radius:0;
	border: none;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2) inset, 0 -1px 0 rgba(0,0,0,0.01) inset;

}

.pane-views-exp-ads-offre-panel-pane-2 form, .pane-views-exp-ads-candidat-panel-pane-1, .pane-views-fab60c4a23027010c9cb7ffc0616f195{
	.form-control{
		background:@white;
	}
}

.form-type-radios{
	.form-radios div{
		margin: 0;
	}
}

label {
    font-family: @font-family-sans-serif;
}


//
// Message alerte
// --------------------------------------------------

.alert{
    borner:none;
    border-radius: 0;
	font-style:italic;
}



//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}
.candidat .dropdown-menu > li.leaf > a, .recruteur .dropdown-menu > li.leaf > a {
	padding:0;
}
.dropdown-menu{
    position: absolute;
    top: 100%;
    z-index: 1000;
    left: 0;
}
.autre {
	margin-left:15px;
	.dropdown-menu{
		top: 73%;
		margin-left:15px;
	}
}

//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  background: none;
  border: 0 none;
  border-radius: 0 ;
  .navbar-nav{
    li{
        position: static;
        a{
            color: @black;
            &:hover{
                color: @orange;
            }
        }
        &.candidat{
            .dropdown-menu{
                background:@orange-dark;
            }
        }
        &.recruteur{
            .dropdown-menu{
                background: @blue;
            }
        }
		.dropdown-menu{
			li.leaf a.nav-header{
				opacity:1;
				transition: @transition-time;
				&:hover{
					opacity:0.6;
				}
			}
		}
    }
  .active > a{
	  background:none;
	  color:@black;
	  &:hover{
		  background:none;
		  color:@orange;
	  }
  }
    &.menu-mon-espace{
        background: @blue;

      .dropdown{
        & > a{
            color:@white;
          background: none;
        }
      }
    }
  }
}

.trouver_offre{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-trouver_offres.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.poster_cv{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-poster_cv.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.candidatures{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-candidatures.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.alertes{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-alertes.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.informations_utiles{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-informations_utiles.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.entreprises{
    &:before{
        background: url('@{images-path}accueil/quicklinks/candidat/picto-entreprises.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.poster_offre{
    &:before{
        background: url('@{images-path}accueil/quicklinks/recruteur/picto-poster_offre.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.cvtheque{
    &:before{
        background: url('@{images-path}accueil/quicklinks/recruteur/picto-cv_theque.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.entreprise_candidatures{
    &:before{
        background: url('@{images-path}accueil/quicklinks/recruteur/picto-candidats.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.entreprise_offres{
    &:before{
        background: url('@{images-path}accueil/quicklinks/recruteur/picto-mes_offres.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.entreprise_alertes{
    &:before{
        background: url('@{images-path}accueil/quicklinks/recruteur/picto-alertes.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
        margin:auto;
    }
}

.stage{
    &:before{
        background: url('@{images-path}accueil/quicklinks/infos_utiles/picto_stage.svg') no-repeat 0 0;
        background-size: 50px 30px;
        content: "";
        width: 50px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.alternance{
    &:before{
        background: url('@{images-path}accueil/quicklinks/infos_utiles/picto_alternance.svg') no-repeat 0 0;
        background-size: 50px 30px;
        content: "";
        width: 50px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.aides{
    &:before{
        background: url('@{images-path}accueil/quicklinks/infos_utiles/picto_aides.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.conseils{
    &:before{
        background: url('@{images-path}accueil/quicklinks/infos_utiles/picto_conseils.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.guides{
    &:before{
        background: url('@{images-path}accueil/quicklinks/infos_utiles/picto_guides.svg') no-repeat 0 0;
        background-size: 30px 30px;
        content: "";
        width: 30px;
        height: 30px;
        display: block;
		margin:auto;
    }
}

.menu-recruteur{
	display: none;
}

.navbar-brand{
	padding:0;
}

.navbar-header{
	float: none;
	background: @gray-light;
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: none;
  @shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);;
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}




//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  	box-shadow:none;
	border-radius:0;
}


 /*heading */

h1{
	text-transform: uppercase;
	color: @black;
}

h2{
	text-transform: uppercase;
	color: @black;
}

.accroche{
    font-style: italic;
    border-bottom: solid 1px @gray-light;
    padding-bottom: 15px;
}

h2.pane-title{
	margin:30px 0 30px 0;
	text-transform: uppercase;
	font-family: @font-family-light;
	color: @black;
	font-size: @font-size-h1;
	border-bottom: solid 1px @gray-border;
	padding-bottom: 1px;
	span{
	   border-bottom: solid 1px @orange;
	}
}

.node-ads-article,
.node-ads-actualite,
.page-offre-postuler,
.node-ads-document,
.poster-offre,
.page-search,
.page-user-poster-offre,
.page-poster-offre{
	h1{
		margin:0 0 30px 0;
		text-transform: uppercase;
		font-family: @font-family-light;
		color: @black;
		font-size: @font-size-h1;
		border-bottom: solid 1px @gray-border;
		padding-bottom:1px;
		span{
		   border-bottom: solid 1px @orange;
		}
	}
}

//
// Adscom
//

.menu-mon-espace{
	background: @blue;
	border-radius: 35px;
	color:@white;
	height:35px;
	margin-top: 15px;
	@media(max-width:@screen-md-max){
		margin-left: 20px;
	}
	li{
	    a{
			text-shadow: none;
	        &.dropdown-toggle{
	            color: @white;
				padding: 7px 15px 0 15px;
				&:hover, &:focus{
					color:@white;
				}
	        }
	    }
	}
	.dropdown-menu{
		li a{
			padding: 5px 20px;
			&:hover, &:focus{
				background:@gray-light;
			color:@orange;
			}
		}

	}
}

.navbar-default {
	.navbar-brand{
		float: none;
		color: @black;
		@media(max-width:@screen-xs-max){
			font-family: @font-family-extrabold;
			font-size: 28px;
		}
		@media(min-width:@screen-sm-min){
			line-height: 33px;
			font-family: @font-family-extrabold;
			font-size: 35px;
		}
		@media(min-width:@screen-md-min){
			line-height: 33px;
			font-family: @font-family-extrabold;
			font-size: @font-size-large;
		}


	}
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus{
	color:@black;
}




//
// Home
// --------------------------------------------------

#navbar{
	margin-bottom:0;
}

 /*logo */

.logo{
	#gradient > .horizontal(@start-color: @orange-dark; @end-color: @orange-light; @start-percent: 0%; @end-percent: 100%);
	margin: 0;
    padding: 12px 15px 13px 15px;
	img{
		@media(min-width:@screen-sm-min){
			margin-left: 10px;
		}
		@media(min-width:@screen-md-min){
			margin-left: 30px;
		}
		@media(min-width:@screen-lg-min){
			margin:0;
		}
	}
}

.site-name{
	@media(max-width:@screen-xs-max){
		padding: 30px 0 21px 15px;
	}
	@media(min-width:@screen-sm-min){
		padding: 30px 0 23px 30px;
	}
	@media(min-width:@screen-md-min){
		padding: 28px 0 20px 30px;
	}
	@media(min-width:@screen-lg-min){
		   padding: 25px 0 11px 30px;
	}
	background-image: url("@{images-path}accueil/header/ombre_logo.png");
    background-repeat: no-repeat;
    background-position: left 0;
}

.site-title{
	position:absolute;
	@media(max-width:@screen-xs-max){
		font-size:24px;
	}
	@media(min-width:@screen-md-min){
		font-size:37px;
	}
	@media(min-width:@screen-lg-min){
		font-size:46px;
		text-shadow: -4px 0px @gray-light, 4px 0px @gray-light ;
	}
}

.lead{
	text-transform: uppercase;
	font-family: @font-family-light;
	border-top: solid 1px @gray-lighter;
	letter-spacing: 1px;
	color:@gray;
	@media(min-width:@screen-sm-min){
		font-size: 18px;
		padding-top:4px;
	}
	@media(min-width:@screen-md-min){
		font-size: 19px;
		padding-top:4px;
	}
	@media(min-width:@screen-lg-min){
		font-size: 24px;
		padding-top:5px;
	}
}

/*pour ie10*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.lead{

		@media(min-width:@screen-lg-min){
			font-size: 24px;
			padding-top:3px;
		}
	}
}

.highlight{
	color:@orange;
}

.mon-espace{
	@media(max-width:@screen-xs-max){
		.fa{
			font-size:30px;
			margin-top:12px;
		}
	}
}

/*menu*/


header#navbar{
	@media(max-width:@screen-xs-max){
		margin-left:15px;
		margin-right:15px;
		margin-bottom:30px;
	}
}



/*recherche*/

#search-api-page-search-form-ads-mdeo-search-page{
	.form-group{
		margin:10px 0 10px 0;
	}
	input.form-control{
		background:none;
		border:1px solid @gray-lighter;
		border-radius:35px;
		box-shadow: none;
	}
	button.btn-default{
		background:none;
		color:@gray-base;
		border:none;
		position:absolute;
		right:15px;
		top:11px;
	}
}

/*forcer la taille des champs*/

.form-type-select.form-item-commune-13.form-item.form-group, .form-type-select.form-item-field-ads-secteur-activite-tid.form-item.form-group, .form-type-select.form-item-commune-offre.form-item.form-group, .form-type-select.form-item-field-ads-offre-niveau-etudes-tid.form-item.form-group, .form-type-select.form-item-field-ads-offre-metier-tid.form-item.form-group, .form-type-select.form-item-field-ads-offre-secteur-activite-tid.form-item.form-group {
	.chosen-container.chosen-container-multi.form-control.form-select.chosen-processed.chosen-with-drop.chosen-container-active, .chosen-container.chosen-container-multi.form-control.form-select.chosen-processed{
		width:100% !important;
	}
}
.form-type-date-popup.form-item-date-demarrage.form-item.form-group{
	width:100%;
	#edit-date-demarrage{
		width:100%;
		.form-type-textfield.form-item-date-demarrage-date.form-item.form-group{
			width:100%;
			#edit-date-demarrage-datepicker-popup-0{
				width:100%;
			}
		}
	}
}

#search-api-page-search-form{
   	margin-bottom: 20px;
}

.search-api-page-results{
	ol.search-results{
		padding-left:30px;
		li.search-result{
			border-bottom:1px solid @gray-lighter;
			&:last-child{
				border:none;
			}
			h3{
				font-family: @font-family-bold;
				text-transform: uppercase;
				a{
					color:@black;
					&:before{
						content:'\f105';
						font-family: FontAwesome;
						padding-right:5px;
					}
					&:hover{
						color:@orange;
						text-decoration:none;
					}
				}
			}
			.search-snippet{
				margin:0;
			}
			.search-info{
				color:@gray-dark;
				font-style: italic;
				font-family: @font-family-sans-serif;
				font-size:13px;
			}
		}
	}
}

/* menu bloc page interne*/

.candidat .menu, .recruteur .menu {
	li.leaf{
		padding:20px 0 20px 0;
		border:none;
		a.nav-header{
			border-right: 1px solid fade(@white,30%);
			transition:@transition-time;
			opacity:1;
			@media(max-width:@screen-xs-max){
				border:none;
			}
			&:hover{
				background:none;
				opacity:0.6;
			}
		}
	}
}

.main-nav .candidat .menu{
	margin-left:15px;
	margin-right: 15px;
}

.main-nav .recruteur .menu{
	margin-left:15px;
	margin-right: 15px;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background:@orange;
	border-radius:0;
	.icon-bar{
		background:@white;
	}
}

.main-nav{
	padding:0;
	background:@white;
}



.menu > li.yamm-fw > a{
	font-family: @font-family-sans-serif;
	@media(min-width:@screen-sm-min){
		padding-top: 15px;
    	padding-left: 0;
		&:after{
			padding-left:20px;
			content:"|";
			color:@gray-border;
		}
	}
}

.main-nav nav .menu.nav.navbar-nav{
	margin-top:2px;
}




.menu > li.yamm-fw:last-child > a{
	&:after{
		content:"";
	}
}

.main-nav .navbar-nav > .open > a{
	background:none;
	color:@black;
}

.main-nav .navbar-nav > .open > a:hover, .main-nav .navbar-nav > .open > a:focus, .main-nav .navbar-nav > .open > a:active{
	background:none;
	color:@orange;
}

.navbar-default .menu-mon-espace > .open > a, .navbar-default .menu-mon-espace > .open > a:hover, .navbar-default .menu-mon-espace > .open > a:focus{
	background:none;
	color:@white;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover{
	@media(max-width:@screen-xs-max){
		color:@white;
	}
}

/*menu interne*/

.candidat .region-navigation .menu{
	background:@orange-dark;
	li.active{
		@media(min-width:@screen-md-min){
			background: url("@{images-path}accueil/active_quicklink.png") no-repeat center bottom;
		}
	}
}

.recruteur .region-navigation .menu{
	background:@blue;
	li.active{
		@media(min-width:@screen-md-min){
			background: url("@{images-path}accueil/active_quicklink.png") no-repeat center bottom;
		}
	}
}


/*menu mobile*/

.navbar-default .navbar-toggle {
    position: relative;
	text-align:center;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 4px;
	.icon-bar {
    	background-color: @orange;
	}
}

.navbar-collapse.main-nav.collapse, .navbar-collapse.main-nav.collapse.in, .navbar-collapse.main-nav.collapsing{
	border:none;
}

.menu-block-wrapper.menu-block-1.menu-name-main-menu.parent-mlid-0.menu-level-1{
	@media(max-width:@screen-xs-max){
		margin:0;
	}
	ul.menu.nav{
		@media(max-width:@screen-xs-max){
			margin:0;
		}
	}
}


.navbar-toggle .icon-bar + .icon-bar{
	margin-top:8px;
}

/*sous menu*/

.main-nav .menu li .dropdown-menu{

	@media(max-width:@screen-xs-max){
		margin-left:0;
	}
	@media(min-width:@screen-sm-min){
		width:@container-sm - @grid-gutter-width;
	}
	@media(min-width:@screen-md-min){
		width:@container-md - @grid-gutter-width;
	}
	@media(min-width:@screen-lg-min){
		width:@container-lg - @grid-gutter-width;
	}
	border:none;
	border-radius:0;
	background:@orange-dark;
	li{
		border-right: 1px solid @white-lighten;
		margin-top:20px;
		margin-bottom:20px;
		a{
			color:@white;
			text-decoration: none;
			font-family: @font-family-bold;
			text-align:center;
			font-size: 18px;
			@media(min-width:@screen-md-min){
				font-size:15px;
			}
			@media(min-width:@screen-lg-min){
				font-size:18px;
			}

		}
	}
}

.menu li .dropdown-menu .leaf a:hover, .menu li .dropdown-menu .leaf a:focus{
	background:none;
}



 /*breadcrumb */
.breadcrumb{
	margin-bottom:0;
	padding:8px 15px 13px 15px;;
	li a {
		text-decoration:none;
		&:hover{
			text-decoration:underline;
		}
	}
	li.active{
		color:@gray-base;
	}
	li + li:before{
		color: @gray-border;
	}
	li:first-child{

		a{
			overflow:hidden;
			text-indent: -10000px;
			display:inline-block;
			padding-right: 15px;
			&:before{
				content: '\f015';
				font-family: FontAwesome;
				position: absolute;
				text-indent:0;
				left:15px;
				margin-top: 4px;
			}
		}
	}
}

.breadcrumb{
	background: none;
	border-radius: 0;
	padding-left: 0;
}


 /*slider */

.carousel-inner{
	background:@gray-light;
	min-height:280px;
}

.carousel-indicators {
	bottom:0;
	@media(max-width:@screen-sm-max){
		visibility:hidden;
	}
	li {
		border: none;
		background-color: @gray-lighter;
	}
	.active {
        background-color: @gray-base;
	}
}

.slideshow{
	margin-top:25px;
	.depositaire{
		font-family: @font-family-sans-serif;
		color:@gray-base;
		font-size:23px;
		@media(max-width:@screen-md-max){
			font-size:@font-size-h2;
		}
		margin:0;
		padding-left: 15px;
	}
	.titre-offre{
		font-family: 'thesans-black';
		color:@black;
		font-size:27px;
		@media(max-width:@screen-md-max){
			font-size:@font-size-h1;
		}
		margin:5px 0 0 0;
		padding-left: 15px;
	}
	img{
		background:@white;
		padding:10px;
		border:1px solid @gray-lighter;
		//width:100% !important;
		@media(max-width:@screen-sm-max){
			max-width: 50%;
		}
	}
}

.btn-slideshow{
	@media(min-width:@screen-md-min){
		margin-bottom:15px;
	}
}

.voir-annonce{
    padding-left: 15px;
	@media(max-width:@screen-sm-max){
		text-align:center;
		margin-bottom:15px;
	}
}

.map-slideshow{
	margin:0;
	padding:0;
	.degrade{
		position:absolute;
		height:100%;
		width:60%;
		@media(max-width:@screen-sm-max){
			bottom:0;
			height:50%;
			width:100%;
		}
	}
	img{
		background:none;
		padding:0;
	}
	.map{
		width:100%;
	}
}

.infos-slideshow{
	margin-top:20px;
	margin-bottom:20px;
	.field-label{
		font-family: @font-family-light;
		font-size:@font-size-base;
		font-style: italic;
		color:@gray-base;
		font-weight: normal;
		@media(max-width:@screen-xs-max){
			display:inline-block;
			padding-right: 15px;
		}
	}
	.field-items{
		@media(max-width:@screen-xs-max){
			display:inline-block;
		}
	}
	.field-item{
		font-family: 'thesans-black';
		color:@black;
		font-size:20px;
		word-wrap: break-word;
    	line-height: 28px;
		@media(max-width:@screen-md-max){
			font-size:@font-size-h2;
		}
		@media(max-width:@screen-xs-max){
			font-size:@font-size-h3;
		}

	}
	.separateur{
		@media(min-width:@screen-sm-min){
			border-right : 1px solid @gray-lighter;
		}
	}
	.field-name-field-ads-offre-type-contrat{
	   padding-left: 15px;
	   .field-label{
		  text-transform: none;
	   }
	}
}



 /*quicklinks */

.tab-home{
	li:first-child{
		width:50%;
		#gradient > .horizontal(@start-color: @orange-dark; @end-color: @orange-light; @start-percent: 0%; @end-percent: 100%);
	}
	li{
		width:50%;
		text-align:left;
		#gradient > .horizontal(@start-color: @blue-light; @end-color: @blue; @start-percent: 0%; @end-percent: 100%);
		a, &.active a, a:hover, a:focus{
			border:none;
			background:none;
			text-transform: uppercase;
			color: @white;
			font-family: @font-family-light;
			font-size:@font-size-base * 2;
			@media(max-width:@screen-xs-max){
				font-size:@font-size-base * 1.5;
			}
		}
	}
}

.tab-home li.active > a:hover, .tab-home li.active > a:focus, .tab-home li > a:hover{
	background:none;
	border:none;
	color:@white;
	border-radius:0;
}

.menu-block-wrapper .menu .leaf .nav-header{
	background:none;
}

.tab-home .tab-candidat{
	&.active a{
		background: none;
	}
	a, a:hover, a:focus{
		background: url("@{images-path}accueil/quicklinks/recruteur/ombre_tab_recruteur.png") no-repeat center right;
		margin:0;
	}
}

.tab-home .tab-recruteur{
	&.active a{
		background: none;
	}
	a, a:hover, a:focus{
		background: url("@{images-path}accueil/quicklinks/candidat/ombre_tab_candidat.png") no-repeat center left;
		margin:0;
	}
}

.menu-candidat{
	background:@orange-dark;
	li{
		border-right: 1px solid @white-lighten;
		padding:0;
		margin-top:20px;
		margin-bottom:20px;
	}
	a.nav-header{
		padding-top:0;
		padding-bottom:0;
		color:@white;
		font-family: @font-family-bold;
		font-size: 19px;
		transition: @transition-time;
		&:hover{
			opacity:0.6;
		}
	}
}

.menu-recruteur{
	background:@blue;
	li{
		border-right: 1px solid @white-lighten;
		padding:0;
		margin-top:20px;
		margin-bottom:20px;
	}
	a.nav-header{
		padding-top:0;
		padding-bottom:0;
		color:@white;
		font-family: @font-family-bold;
		font-size: 19px;
		transition: @transition-time;
		&:hover{
			opacity:0.6;
		}
	}
}


 /*guides home */

.view-ads-document .views-bootstrap-grid-plugin-style .node-ads-document{
	margin-bottom:30px;
}

.view-ads-document .views-bootstrap-grid-plugin-style .docs-accueil{
	margin-bottom:0px;
}

.node-teaser, .teaser-document{
	h2{
		text-transform: none;
		font-family: @font-family-light;
		font-size:22px;
		margin-top: -53px;
    	position: absolute;
    	background: @white;
		padding:10px 5px 0 0;
		width:80%;
		a{
			color: @gray-base;
			text-decoration: none;
			transition:@transition-time;
			&:hover{
				color:@orange;
			}
		}
	}
}

.teaser-document{
    h2{
        position: relative;
    }
}

/*actus home*/

.node-ads-actualite{
	.date{
	   float: left;
	   font-family: @font-family-light;
	   color: @gray-base;
	   font-size: @font-size-h3;
	   margin-right: 20px;
		br{
			line-height:1px;
		}

	   span{
	       font-family: 'thesans-black';
	       font-size: @font-size-medium;
	       border-bottom: solid 1px @gray-lighter;
	   }
	}
	p{
		color:@black;
		margin-bottom: 15px;
	}
	h4{
	   a{
	       color: @black;
	       text-transform: uppercase;
	       font-family: @font-family-bold;
	       font-size: 20px;
		   text-decoration: none;
		   line-height:24px;
		   transition:@transition-time;
		   &:hover{
				color:@orange;
			}
	   }
	}
}

.plus_actus{
	position: absolute;
	right:15px;
	top:30px;
	.link-title{
		background-image: url("@{images-path}accueil/btn-plus.png");
    	width: 23px;
		height: 24px;
		border-radius: 100%;
		text-align: center;
		color: @white;
		font-size:17px;
		overflow: hidden;
    	text-indent: -10000px;
	}

	.link-url a{
		text-indent: -10000px;
    	display: block;
    	overflow: hidden;
    	margin-top: -23px;
	}
}

.pane-content .row .col .node-ads-actualite{
	border-bottom:1px solid @gray-lighter;
	margin:0 0 10px 0;
	&:last-child{
		border:none;
	}
}



/* footer */

h2.block-title{
	color: @black;
	font-family: @font-family-light;
}

#footer-top{
	background: @gray-light;
	text-align: center;
	padding: 0 0 30px 0;
	margin-top:50px;
	ul{
	   list-style: none;
		padding:0;
		margin:0;
	   li{
	       display: inline-block;
	       padding:5px 20px;
		   @media(max-width:@screen-xs-max){
			   display:block;
			   padding:15px 0;
		   }
		   @media(max-width:@screen-sm-max){
			   padding:5px 15px;
		   }
       }
	}
}

#footer-bottom{
	background: @gray-dark;
	font-size:13px;
	ul{
	   li{
	       a{
	           color: @white;
	           font-family: @font-family-light;
	           text-decoration: underline;
			   transition:@transition-time;
			   padding-right: 5px;
    			padding-left: 5px;
			   &:hover{
				   background:none;
				   opacity:0.6;
			   }
	       }
	   }
	}
}

.logo-footer{
	#gradient > .horizontal(@start-color: @orange-dark; @end-color: @orange-light; @start-percent: 0%; @end-percent: 100%);
	margin: 0;
    padding: 5px 10px 5px 10px;
	.logo-footer-mde{
		height:40px;
	}
}

.texte-logo{
	p{
		color:@white;
		font-family: @font-family-bold;
		font-size: 13px;
		margin:8px 0 0 0;
		padding:0;
	}
}

#footer-bottom > .container > .row > div > ul.nav{
	list-style-type: none;
	text-align: center;
	float:none;
	margin:0;
}

#footer-bottom > .container > .row > div > ul.nav li.leaf{
	display: inline-block;
	float:none;
}


//
// listing offres
// --------------------------------------------------


/*badge*/

.espace{
	span{
		color:@white;
		padding: 1px 5px 1px 5px;
		font-family: @font-family-light;
		font-size:18px;
		position:absolute;
		top:0;
		right:25%;
		transform: rotate(-15deg);
		margin-top: 18px;
		@media(max-width:@screen-md-max){
			right:6%;
		}
	}
	.badge-recruteur{
		background:@blue;
	}
	.badge-candidat{
		background:@orange;
	}
}

.pane-ads-offre-panel-pane-2, .pane-ads-document-panel-pane-2, .pane-ads-entreprise-panel-pane-1, .pane-ads-candidat-panel-pane-1, .pane-facetapi-gq0jqhwdkrpa2jy03akusgtyknipzkrj{
	h2.pane-title {
		margin-top:0px;
		@media(max-width:@screen-sm-max){
			margin-top:30px;
		}
	}
}

.pane-facetapi-gq0jqhwdkrpa2jy03akusgtyknipzkrj{
	h2{
		margin-bottom:20px;
	}
}

.pane-adscom-document-solr-panel-pane-1{
	h2.pane-title {
		margin-bottom:0px;
	}
}

.node-ads-document, .pane-adscom-document-solr-panel-pane-1{
	h2.pane-title {
		margin-top:0px;
	}
}


/*menu*/

.candidat .menu li, .recruteur .menu li{
	/*margin:20px 0 20px 0;*/
	border-right: 1px solid rgba(255, 255, 255, 0.3);
	.nav-header{
		color: #ffffff;
		text-decoration: none;
		font-family: @font-family-bold;
		font-size: 18px;
		text-align: center;
		@media(min-width:@screen-md-min){
			font-size:15px;
		}
		@media(min-width:@screen-lg-min){
			font-size:18px;
		}
	}
}


/*recherche*/

.views-exposed-form{
	background:@gray-light;
	padding:25px;
	.views-exposed-widget {
		width:100%;
		padding:10px 0 15px 0;
		border-bottom:1px solid @gray-lighter;
		label{
			color:black;
			font-family: @font-family-sans-serif;
			font-size: @font-size-base;
		}
		&:last-child{

		}
		.form-item-date-demarrage-date{
			label{
				display:none;
			}
		}
	}
	.views-widget-filter-date_demarrage, .views-widget-filter-field_ads_entreprise_collegien_value, .views-widget-filter-field_ads_preferences_secteurs_tid{
		border:none;
	}
	.views-submit-button, .views-reset-button{
		border:none;
		text-align:center;
		margin:0;
		padding:0;
	}
	.views-reset-button{
		border-top:1px solid @gray-lighter;
		margin-top:15px;
	}
	.views-submit-button{
		.form-submit{
			margin:0;
			background:@blue;
			border:1px solid @blue;
			&:hover, &:focus, &:active, &:active:hover, &:active:focus{
				color:@blue;
				background:none;
				border:1px solid @blue;
				box-shadow: none;
			}
		}
	}
	.views-reset-button{
		#edit-reset{
			text-shadow:none;
			background:none;
			color:@blue;
			border:none;
			text-decoration:underline;
			box-shadow:none;
			margin:0;
			transition:@transition-time;
			&:hover{
				color:@orange;
			}
		}
	}
}

input#edit-date-demarrage-datepicker-popup-0{
	background: url("@{images-path}content/icon-calendrier.png") no-repeat @white scroll 7px 7px;
	background-size: 20px;
    padding-left: 40px;
}

.form-type-textfield.form-item-field-ads-candidat-naissance-und-0-value-date.form-item.form-group, .form-type-textfield.form-item-field-ads-offre-debut-und-0-value-date.form-item.form-group, .form-type-textfield.form-item-field-ads-offre-date-limite-und-0-value-date.form-item.form-group{
	input.date-clear.form-control.form-text{
		background: url("@{images-path}content/icon-calendrier.png") no-repeat @gray-form scroll 7px 7px;
		background-size: 20px;
		padding-left: 40px;
	}
}
.form-type-textfield.form-item-publish-on-date.form-item.form-group, .form-type-textfield.form-item-unpublish-on-date.form-item.form-group{
	input.form-control.form-text{
		background: url("@{images-path}content/icon-calendrier.png") no-repeat @gray-form scroll 7px 7px;
		background-size: 20px;
		padding-left: 40px;
	}
}

/*listes*/

.node-ads-offre, .node-ads-entreprise{
	h4{
		margin:0;
		color:@black;
		font-family: @font-family-extrabold;
		font-size: @font-size-h1;
		text-transform: uppercase;
		a{
			color:@black;
			font-family: @font-family-extrabold;
			font-size: @font-size-h1;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
			&:hover{
				color:@orange;
			}
		}
	}
	p.candidater{
		font-size: 17px;
		color:@gray-base;
		font-family: @font-family-sans-serif;
	}
	.field-name-field-ads-offre-type-contrat, .field_ads_secteur_activite, .field-name-field-ads-entreprise-compl-rs{
		color:@gray-base;
		text-transform: uppercase;
		font-family: @font-family-sans-serif;
		font-size: 16px;
	}

	&.postuler{
	   .field-name-field-ads-offre-type-contrat{
	       text-transform: none;
	       font-size: 17px;
           font-family: @font-family-bold;
           color: #000;
	   }
    }


	.field-name-field-ads-offre-reference{
		font-size:14px;
		font-family: @font-family-light;
		color:@gray-dark;
    	padding: 5px 0 10px 0;
	}
}

.well{
	padding: 15px;
	img{
		background: @white;
    	padding: 10px;
		border: 1px solid @gray-border;

		&.logo{
		  padding: 0;
		}
	}

	.field-label{
		color:@gray-base;
		font-size:@font-size-base;
		font-family: @font-family-sans-serif;
		font-style: italic;
		font-weight: normal;
		@media(max-width:@screen-sm-max){
			display:inline-block;
			padding-right:15px;
		}
	}
	.field-items{
		word-wrap: break-word;
		@media(max-width:@screen-sm-max){
			display:inline-block;
		}
	}
	hr{
		/*margin:0 15px 0 15px;*/
		margin:0;
	}
	@media(max-width:@screen-xs-max){
		.titre-entreprise{
			margin:15px 0;
		}
	}
	.detail-liste{
		color:@black;
		font-size:17px;
		font-family: @font-family-bold;
		@media(max-width:@screen-md-max){
				padding-top: 15px;
				font-size:15px;
			}
		@media(max-width:@screen-sm-max){
				padding-top: 15px;
				font-size:17px;
			}
		.separateur{
			@media(min-width:@screen-md-min){
				border-right:1px solid @gray-lighter;
			}
		}
	}
}

.listing .col div:nth-child(2n+1){
	.well{
		background:@white;
	}
}

.view-id-ads_entreprise .view-content #views-bootstrap-grid-1 .row:nth-child(2n+1){
	.col div .well{
		background:@white;
	}
}

.view-id-ads_candidat .view-content #views-bootstrap-grid-1 .col div:first-child{
	.well{
		background:@white;
	}
}

.detail .item-list ul li:nth-child(2n+1){
	.node-ads-offre .well{
		background:@white;
	}
}

//
// detail offre
// --------------------------------------------------


.jumbotron{
	background:none;
	border-top:1px solid @gray-border;
	border-bottom:1px solid @gray-border;
	margin:0 0 30px 0;
	p, .field{
		color:@black;
		font-size:15px;
	}
	.field{
		font-size:15px;
		padding-top: 5px;
	}
	.field-name-field-ads-entreprise-compl-rs, .field-name-field-ads-offre-type-contrat{
		color:@gray-base;
		padding:0;
	}
	.field-name-field-ads-offre-reference{
		color:@gray-dark;
		margin-top:15px;
		padding:0;
	}
	.logo-entreprise{
		img{
			margin-top: 15px;
    		padding: 5px;
			float:right;
		}
	}
	.nom-poste{
		color:@black;
		font-size:40px;
		font-family: 'thesans-black';
		margin-bottom:0;
	}
	.badge{
		color:@orange;
		font-weight:normal;
		margin-bottom:15px;
		.field{
			padding-top:0;
		}
		.field-item{
			color:@orange;
		}
	}
	h2{
		margin-top:0;
		.field-item{
			font-size: 19px;
			font-family: @font-family-light;
		}
	}
	.btn{
		margin-top:25px;
		margin-bottom:15px;
		box-shadow: none;
		@media(max-width:@screen-xs-max){
			    display: inherit;
		}
		@media(max-width:@screen-sm-max){
			margin-bottom:15px;
		}
	}
	.btn-alt{
		background: @blue;
		border:1px solid @blue;
		color:@white;
		font-size:17px;
		font-family: @font-family-sans-serif;
		border-radius:35px;
		transition:@transition-time;
		&:hover, &:focus, &:active, &:active:hover, &:active:focus{
			background:none;
			border:1px solid @blue;
			color:@blue;
			box-shadow: none;
			a{
				color:@blue;
			}
		}
		a{
			text-decoration:none;
			color:@white;
			transition:@transition-time;
		}
	}

	.field-name-field-ads-offre-debut, .field-name-field-ads-offre-duree{
		.field-label{
			color:@black;
			font-size : @font-size-h2;
			font-family: @font-family-bold;
		}
		.field-item{
			color:@black;
			font-size : @font-size-h2;
			line-height:1.66em;
		}
	}
	.infos-header{
		margin-bottom:15px;
		.street-block{
			display:inline-block;
		}
		.field-label{
			display:inline-block;
		}
	}
	.field-name-field-ads-entreprise-siteweb{
		.field-items a{
			line-height: 18px;
    		display: block;
			word-wrap: break-word;
		}
	}
}

.btn-jumbotron{
	@media(max-width:@screen-sm-max){
		text-align:center;
	}
}

.detail{
	.field{
		.field-label{
			font-size : @font-size-h1;
			font-family: @font-family-light;
			color:@blue;
			text-transform: uppercase;
			line-height: 2;
			margin-top:15px;
			font-weight:normal;
		}
	}
	.field-item ul{
		list-style:none;
		li:before{
			content:'\2022';
			color:@orange;
			padding-right:5px;
		}
	}
	.item-list{
		margin-top:50px;
		h3{
			font-size : @font-size-h2;
			font-family: @font-family-light;
			color:@blue;
			border-top: 1px solid @gray-border;
    		padding-top: 15px;
		}
		ul{
			margin:0;
			padding:0;
			list-style: none;
		}
		.field-label{
			color: #5c5747;
			font-size: 13px;
			font-family: @font-family-sans-serif;
			font-style: italic;
			font-weight: normal;
			text-transform: none;
			line-height:normal;
			margin-top:0;
		}
	}
	.field-name-field-ads-entreprise-contenu{
		.field-label{
			margin-top:0;
		}
	}
}

.infos-pratiques{
	@media(max-width:@screen-sm-max){
		margin-top: 30px;
	}
	h3{
		text-align:center;
		font-size : @font-size-h1;
		font-family: @font-family-light;
		color:@blue;
		text-transform: uppercase;
		line-height: 2;
		margin-top:0;
	}
	.field{
		color:@black;
		word-wrap: break-word;
	}
	.field, h3{
		padding: 10px 15px;
		margin-bottom: -1px;
		border: 1px solid @gray-border;
	}
	.btn-simple{
		color:@blue;
		&:hover{
			text-decoration:none;
			color:@orange;
		}
	}
	.retour:before{
			content:'\f0a8';
			font-family: FontAwesome;
			padding-right:5px;
		}
	.share{
		img{
			width:40px;
			height:40px;
			transition:@transition-time;
			&:hover{
				opacity:0.6;
			}
		}
		ul{
			margin-bottom:0;
		}
	}
}

/*offres similaires*/

.offres-similaires{
	.item-list{
		h3{
			font-size : @font-size-h2;
			font-family: @font-family-light;
			color:@blue;
			border-top: 1px solid @gray-border;
    		padding-top: 15px;
		}
		h4{
			margin-bottom:10px;
		}
		.depositaire{
			font-family: @font-family-sans-serif;
			color:@gray-base;
			font-size:@font-size-h2;
			margin:0;
			border:none;
		}
		ul {
			list-style: none;
			padding-left:0;
		}
		.field{
			word-wrap: break-word;
		}
		.field-label{
			color:@gray-base;
			font-size:@font-size-base;
			font-family: @font-family-sans-serif;
			font-style: italic;
			font-weight: normal;
			@media(max-width:@screen-sm-max){
				display:inline-block;
				padding-right:15px;
			}
		}
		.field-items{
			color: @black;
			font-size: 17px;
			font-family: @font-family-bold;
			@media(max-width:@screen-sm-max){
				display:inline-block;
			}
		}
		.separateur{
			@media(min-width:@screen-md-min){
				border-right:1px solid @gray-lighter;
			}
		}

	}
}


//
// recherche doc
// --------------------------------------------------


.view-adscom-document-solr .views-exposed-form {
	background:none;
	padding:0 0 15px 0;
	//border-bottom:1px solid @gray-border;
	.views-widget-filter-search_api_views_fulltext{
		border:none;
		display:inline-block;
		.views-widget{
			display:inline-block;
			margin-left:30px;
			width:85%;
			@media(max-width:@screen-md-max){
				width:82%;
			}
			@media(max-width:@screen-sm-max){
				margin-left:0px;
				width:100%;
			}


		}
	}
}

ul.facetapi-facetapi-checkbox-links{
	padding:0;
	list-style: none;
	li{
		border-bottom:1px solid @gray-lighter;
		margin:5px 0 5px 0;
		&:last-child{
			border:none;
		}
	}
	.facetapi-checkbox{
		margin-right:5px;
		margin-bottom:10px;
	}
}

.btn-info{
	font-size:17px;
	font-family: @font-family-sans-serif;
	border-radius:35px;
	transition:@transition-time;
	text-shadow:none;
	box-shadow: none;
}

#views-exposed-form-adscom-document-solr-panel-pane-1{
	margin-bottom:30px;
}

.node-ads-document .teaser-document{
	margin-bottom:30px;
}

.node-ads-document{
	p{
		color:@black;
		font-family: @font-family-sans-serif;
	}
	.field-label{
		font-size : @font-size-h1;
		font-family: @font-family-light;
		color:@blue;
		text-transform: uppercase;
		line-height: 2;
		margin-top:15px;
		font-weight:normal;
	}
	.badge{
		margin-bottom:15px;
		font-size:15px;
		@media(max-width:@screen-sm-max){
			margin-top:15px;
		}
		a, .field-item{
			font-weight:normal;
			text-decoration:none;
			color:@orange;
			font-family: @font-family-sans-serif;
		}
	}
	.field-name-field-ads-document-accroche{
		color:@gray-dark;
		font-style: italic;
		font-family: @font-family-sans-serif;
		font-size:16px;
	}
	.field-name-field-ads-document-image{
		img{
			width:100%;
			margin-bottom:30px;
		}
	}
	.field-name-field-ads-document-contenu{
		margin-left:15px;
		margin-right:15px;
		h2{
			color:@black;
			font-family: @font-family-bold;
			font-size:@font-size-h2;
		}
		h3{
			color:@black;
			font-family: @font-family-light;
			font-size:@font-size-h2;
		}
		ul li{
			margin-left:0;
		}
	}
}



//
// cv-theque
// --------------------------------------------------


/*liste*/

span.accessible{
	font-size:14px;
	font-family: @font-family-light;
	color:@gray-dark;
	margin-bottom: 15px;
	padding: 10px;
}
.titre-cv{
	margin:0;
}
.list-cv .detail-liste{
	margin-top:15px;
}
.ads-contact-ads-candidat{
	.field-name-field-ads-candidat-photo{
		img{
			padding:0;
		}
	}
	h2 {
		margin:0;
		.field-name-field-ads-candidat-titre-cv .field-item{
			text-transform: uppercase;
			font-family: @font-family-extrabold;
			font-weight: bold;
			font-size: 25px;
			text-decoration: none;
			line-height:24px;
			transition:@transition-time;
		}
		a{
			color:@black;
			&:hover{
				color:@orange;
				text-decoration: none;
			}
		}
	}
}



.field-name-field-ads-candidat-photo{
	margin-bottom:15px;
}

/*fiche cv*/

.cv{
	h1{
		margin-bottom:0;
		.field-item{
			color:@black;
			font-size:40px;
			font-family: 'thesans-black';
			margin-bottom:0;
		}
	}
	h2{
		font-family: @font-family-light;
	}
	.badge .field-item{
		color:@orange;
		font-weight:normal;
		padding:5px;
	}
	field-label{
		font-size:15px;
	}
	.badge .field-item{
		padding:0;
	}
}
.field-name-field-ads-candidat-commentaires{
	.field-label{
		font-size: 25px;
		font-family: @font-family-light;
		color: #0b81b7;
		text-transform: uppercase;
		line-height: 2;
		margin-top: 0;
		font-weight: normal;
	}
}
.infos-pratiques .field.field-name-field-ads-candidat-preferences.field-type-entityreference{
	border:none;
	padding:0;
}

#tooltip_preferences{
  background: none;
  padding: 0;

}

//
// actualité
// --------------------------------------------------

/*fiche actu*/

.node-ads-actualite{
	blockquote {
		padding: 15px 0 15px 0;
		margin: 0 0 21px;
		font-size: 20px;
		font-style:italic;
		font-family: @font-family-light;
		border-bottom: 1px solid @gray-lighter;
		border-left:none;
		color:@black;
	}
	.pane-node-field-ads-actualite-image{
	   margin-top :@grid-gutter-width / 2;
	   }
	.pane-ads-actualite-views-ads-actualite-views-archive{
		h4{
			color:@black;
			margin:0;
			font-size:17px;
			font-family: @font-family-sans-serif;
		}
		ul{
			list-style: none;
			padding-left:10px;
			padding-right:10px;
			li {
				border-bottom:1px solid @gray-lighter;
				padding-top:10px;
				padding-bottom:10px;
				&:last-child{
					border:none;
				}
				a{
					color:@gray-base;
					text-decoration:none;
					&.active, &:hover{
						color:@orange;
					}
				}
			}
		}
	}
}

.pane-node-field-ads-actualite-fichiers, .pane-ads-actualite-views-ads-actualite-views-archive, .pane-node-field-ads-article-liens, .pane-node-field-ads-document-liens-utiles, .pane-facetapi-gq0jqhwdkrpa2jy03akusgtyknipzkrj{
	background:@gray-light;
	margin-bottom:30px;
	h2.pane-title{
		border: none;
    	background: @blue;
    	padding: 10px;
		font-family: @font-family-bold;
		font-size:@font-size-h2;
		color:@white;
		margin:0;
		span{
			border:none;
		}
	}
	.pane-content{
		padding: 15px;
		.field-item{
			border-bottom: solid 1px @gray-lighter;
			&:last-child{
				border:none;
			}
			padding:10px 0;
			a{
				color:@gray-base;
				font-size:@font-size-base;
				font-family: @font-family-sans-serif;
				&:hover{
					text-decoration: none;
					color:@orange;
				}
			}
			img.file-icon{
				display:none;
			}
		}
	}
}

.pane-node-field-ads-actualite-fichiers{
	h2.pane-title{
		&:before{
			content:"\f0ed";
			font-family: FontAwesome;
			padding-right:10px;
		}
		span{
			border-left: 1px solid fade(@white,50%);
			padding-left: 10px;
		}
	}
}

.pane-ads-actualite-views-ads-actualite-views-archive{
	h2.pane-title{
		&:before{
			content:"\f115";
			font-family: FontAwesome;
			padding-right:10px;
		}
		span{
			border-left: 1px solid fade(@white,50%);
			padding-left: 10px;
		}
	}
}

.pane-node-field-ads-document-liens-utiles{
	h2.pane-title{
		&:before{
			content:"\f0c1";
			font-family: FontAwesome;
			padding-right:10px;
		}
		span{
			border-left: 1px solid fade(@white,50%);
			padding-left: 10px;
		}
	}
}

.field-name-field-ads-document-fichiers{
	background:@gray-light;
	.field-label{
		border: none;
		background: @blue;
		margin: 0;
		padding: 0;
		font-family: @font-family-bold;
		font-size: 20px;
		color: @white;
		padding-left: 10px;
	}
	.field-items{
		padding:15px;
		.field-item {
			img{
				display:none;
			}
			a{
				color:@gray-base;
				font-size:@font-size-base;
				font-family: @font-family-sans-serif;
				&:hover{
					text-decoration: none;
					color:@orange;
				}
				&:before{
					content:"\f0ed";
					font-family: FontAwesome;
					padding-right:5px;
				}
			}
		}
	}
}


.pane-node-field-ads-actualite-fichiers{
	.pane-content .field-item a:before{
		content:"\f0ed";
		font-family: FontAwesome;
		padding-right:5px;
	}
}

.pane-ads-actualite-views-ads-actualite-views-archive{
	.pane-content .item-list ul li a:before{
		content:"\f105";
		font-family: FontAwesome;
		padding-right:5px;
	}
}


/*page article*/

.node-ads-article{
	.pane-node-field-ads-article-accroche{
		.accroche {
			padding: 15px 0 15px 0;
			margin: 0 0 21px;
			font-size: 20px;
			font-style:italic;
			font-family: @font-family-light;
			border-bottom: 1px solid @gray-lighter;
			border-left:none;
			color:@black;
		}
	}
	.pane-node-field-ads-article-contenu{
		margin-bottom: 15px;
		h2{
			font-family: @font-family-bold;
			font-size: @font-size-h2;
			text-transform: uppercase;
		}
		h3{
			font-family: @font-family-light;
			font-size: @font-size-h2;
		}
		p{
			color: @black;
		}
	}
	.pane-node-links.link-wrapper{
		position: absolute;
    	right: 0;
    	top: -65px;
	}
}

ul.links{
	margin-bottom:0;
	li.print, li.email, li.facebook{
		width:40px !important;
		transition:@transition-time;
		&:hover{
			opacity:0.6;
		}
	}
}

//
// nous contacter
// --------------------------------------------------


.webform-client-form .form-actions{
	clear:both;
	width: 100%;
	text-align:center;
}
.form-item.webform-component.webform-component-textarea.webform-component--contact-message.form-group{
	label{
		display:block;
	}
	.form-textarea-wrapper{
		width:100%;
	}
}

/*captcha*/

.form-type-textfield.form-item-captcha-response.form-item.form-group{
	p.help-block{
		display:none;
	}
}
fieldset.captcha.panel.panel-default.form-wrapper{
        .make-md-column(12,@grid-gutter-width);
	.panel-body{
		.make-row(@grid-gutter-width);
	}
	.help-block{
		.make-md-column(12,@grid-gutter-width);
	}
	.form-type-textfield.form-item-captcha-response.form-item.form-group{
		.make-md-column(7,@grid-gutter-width);
	}
}
fieldset.captcha.panel.panel-default.form-wrapper .help-block{
	@media(min-width:@screen-md-min){
		float:right;
	}
}

//
// login
// --------------------------------------------------

.pane-user-login{
	color:@black;
	ul{
		list-style: none;

		text-align:right;
		li a{
			color:@blue;
			&:hover{
				color:@orange;
			}
		}
	}
}
p.nouveau{
	font-family: @font-family-bold;
	font-size: 20px;
	text-transform: uppercase;
}
p.inscription{
	color:@gray-base;
}
.btn-default{
	background: @orange;
	box-shadow: none;
	text-shadow: none;
	border:1px solid @orange;
	color:@white;
	font-size:17px;
	font-family: @font-family-sans-serif;
	border-radius:35px;
	transition:@transition-time;
	&:hover, &:focus, &:active, &:active:hover, &:active:focus{
		background:none;
		border:1px solid @orange;
		color:@orange;
		box-shadow: none;
	}
}
.panel-pane.pane-block.pane-user-login{
	h2.pane-title{
		text-transform: none;
		font-size: @font-size-h2;
	}
}

.page-user.logged-in ul.tabs--primary.nav.nav-tabs li:first-child{
	display: none;
}
/*
.page-user.logged-in .er-candidat ul.tabs--primary.nav.nav-tabs li:nth-child(3){
    display: none;
}*/
/*
.page-user.logged-in .er-entreprise ul.tabs--primary.nav.nav-tabs li:nth-child(6){
    display: none;
}*/


//
// poster offre
// --------------------------------------------------


#ads-offre-node-form{
	h3{
		margin:40px 0 30px 0;
		font-family: thesans-light;
		color: @black;
		font-size: 20px;
		border-bottom: solid 1px @gray-border;
		span{
		   border-bottom: solid 1px @orange;
		}
	}
	.form-item-title{
		input{
			font-size:@font-size-base;
		}
	}
	#edit-actions{
		text-align:center;
		.btn-default{
			background: @orange;
			box-shadow: none;
			text-shadow: none;
			border:1px solid @orange;
			color:@white;
			font-size:17px;
			font-family: @font-family-sans-serif;
			border-radius:35px;
			transition:@transition-time;
			&:hover, &:active{
				background:none;
				box-shadow: none;
				border:1px solid @orange;
				color:@orange;
			}
		}
	}
	.field-name-field-ads-offre-nb-postes{
		margin-bottom:14px;
		.form-item-field-ads-offre-nb-postes-und-0-value{
			margin-bottom:0;
		}
	}
	.field-type-list-boolean{
		.form-type-radios{
			margin-bottom:0;
			label{
				line-height: 23px;
			}
		}
	}
}

#edit-field-ads-offre-description-und-0-format, #edit-field-ads-offre-mission-und-0-format, #edit-field-ads-offre-profil-und-0-format, #edit-field-ads-offre-modalites-und-0-format, #edit-field-ads-candidat-commentaires-und-0-format{
	display:none;
}

.field-type-datestamp, #field-ads-candidat-naissance-add-more-wrapper{
	fieldset{
		margin-top: -7px;
		margin-bottom:0;
		border: none;
    	box-shadow: none;
		legend.panel-heading {
			margin:0;
			padding:0;
			span.fieldset-legend, a.fieldset-legend {
				display: inline-block;
				max-width: 100%;
				margin-bottom: 5px;
				font-weight: bold;
				color:@black;
				font-size:15px;
				text-transform: none;
				font-family: @font-family-sans-serif;
			}
		}
		.form-item-field-ads-offre-debut-und-0-value-date, .form-item-field-ads-offre-date-limite-und-0-value-date, .form-item-field-ads-candidat-naissance-und-0-value-date{
			label, p.help-block{
				display:none;
			}
		}
		.panel-body{
			margin:0;
			padding:0;
			.form-type-date-popup{
				margin:0;
				.date-padding{
					padding:0;
				}
			}
		}
	}
}

.form-type-radios.form-item-field-ads-offre-permanente-und.form-item.form-group{
	.make-md-column(6);
}

fieldset.collapsible.panel.panel-default.form-wrapper.collapse-processed > div.panel-collapse.collapse.fade.in{
	.make-row(@grid-gutter-width);
	.panel-body div.container-inline-date{
		clear:none;
		.make-md-column(6);
		.form-type-date-popup .date-padding{
			.make-md-column(12);
		}
	}
}

.page-user-poster-offre,
.page-poster-offre{
    .er-entreprise{
        #edit-publish{
            display: none;
        }

    }
}

.node-type-ads-offre{
	.er-entreprise{
	   .tabs--primary{
	       &.nav-tabs{
	           li:nth-child(2){
	               display: none;
	           }
	       }
	   }
    }
}


//
// connexion/inscription
// --------------------------------------------------

.btn-default{
	background: @orange;
	box-shadow: none;
	text-shadow: none;
	border:1px solid @orange;
	color:@white;
	font-size:17px;
	font-family: @font-family-sans-serif;
	border-radius:35px;
	transition:@transition-time;
	&:hover, &:active{
		background:none;
		box-shadow: none;
		border:1px solid @orange;
		color:@orange;
	}
}


#user-login{
	/*margin-top:30px;*/
}


//
// profil
// --------------------------------------------------


.tabs--primary{
	border-bottom:1px solid @gray-border;
	text-transform: uppercase;
	margin-bottom:30px;
	li a{
		margin:0 40px 0 0px;
		padding:0;
		font-size: 20px;
		@media(max-width:@screen-md-max){
			margin:0 30px 0 0px;
			font-size: 18px;
		}
		@media(max-width:@screen-sm-max){
			margin:0 20px 0 0px;
			font-size: 15px;
		}
		color:@black;
		font-family: @font-family-bold;
		border:none;
		padding-bottom:0;
		@media(max-width:@screen-xs-max){
			padding:0 5px 0 5px;
			font-size: 17px;
		}
	}
	li a.active{
		background:none;
		color:@orange;
		border-bottom:1px solid @orange;
		border-top:none;
		border-right:none;
		border-left:none;
		&:hover{
			border-bottom:1px solid @orange;
			border-top:none;
			border-right:none;
			border-left:none;
			color:@orange;
			background:none;
		}
	}
	li a:hover, la a:focus{
		border-bottom:1px solid @orange;
		border-top:none;
		border-right:none;
		border-left:none;
		color:@orange;
		background:none;
	}
}

.tabs--primary > li > a:hover, .nav > li > a:focus{
	background:none;
}

fieldset{
	legend.panel-heading{
		margin-left:10px;
		padding: 0px 5px;
		background:none;
		border:none;
		width:auto;
		margin-bottom:0;
		padding-bottom: 0;
		background-image:none;
		.fieldset-legend{
			color:@blue;
			font-size: 25px;
    		font-family: @font-family-light;
			text-transform: uppercase;
			&:hover{
				text-decoration:none;
			}
		}
	}
}

.help-block, .search-performance{
	color:@gray-dark;
	font-style: italic;
	font-family: @font-family-sans-serif;
	font-size:13px;
}
.search-performance{
	float: right;
	margin-top: -45px;
}


.panel-default > .panel-heading{
	background:none;
}

.panel-default{
	border-color:@gray-border;
}

.vertical-tabs-panes.vertical-tabs-processed.tab-content{
	min-height:0 !important;
}

.btn-success{
	background:@orange;
	text-shadow: none;
	box-shadow: none;
	border-color:@orange;
	&:hover, &:active, &:focus{
		background:none;
		color:@orange;
	}
}

.field-type-image .btn-danger, .field-type-file .btn-danger, .imagefield-crop-widget .btn-danger{
	margin-left:15px;
	background:@blue;
	border:1px solid @blue;
	box-shadow: none;
	text-shadow: none;
	border-radius: 35px;
	&:hover{
		color:@blue;
		background:none;
		border:1px solid @blue;
	}
}

form#ads-contact-form {
	.form-type-textfield.form-item-prenom.form-item.form-group, .form-type-textfield.form-item-nom.form-item.form-group{
		.make-md-column(6,@grid-gutter-width);

		@media(min-width: @screen-md-min){
		  width: 50%;
		  float: left;
		}
		@media(max-width: @screen-sm-max){
		  width: 100%;
		  float: left;
		}
	}
	& > div{
		.make-row(@grid-gutter-width);
	}

	#edit-submit{
	   clear: both;
	   display: block;
	   margin: 0 auto;
    }

}



/*postuler*/

.postuler{
	.detail-liste{
		color:@black;
		font-size:17px;
		font-family: @font-family-bold;
		margin-bottom: 15px;
		@media(max-width:@screen-sm-max){
				padding-top: 15px;
			}
		.separateur{
			@media(min-width:@screen-md-min){
				border-right:1px solid @gray-lighter;
			}
		}
		.field{
			word-wrap: break-word;
		}
	.field-label{
		color:@gray-base;
		font-size:@font-size-base;
		font-family: @font-family-sans-serif;
		font-style: italic;
		font-weight: normal;
		@media(max-width:@screen-sm-max){
			display:inline-block;
			padding-right:15px;
		}
	}
	.field-items{
		@media(max-width:@screen-sm-max){
			display:inline-block;

		}
	}
	}
}

.infos-persos{
	a.edit{
		position: absolute;
    	top: 35px;
    	right: 15px;
		color:@blue;
		&:hover{
			color:@orange;
			text-decoration: none;
		}
		&:before{
			content:'\f040';
			font-family: FontAwesome;
			padding-right:5px
		}
	}
}

.motivation{
	.form-item-files-candidature-lettre-motivation .form-control{
		background:none;
		box-shadow: none;
		padding-left:0;
	}
}


/*sitemap*/

#site-map{
	.site-map-box ul ul{
		margin: 5px 0 15px 0;
	}
	.site-map-box-front{
		a{
			font-family: @font-family-bold;
			text-transform: uppercase;
			font-size:@font-size-h2;
			color:@gray-base;
			text-decoration: none;
			&:hover{
				color:@orange;
			}
		}
	}
	.site-map-menus{
		ul.site-map-menu{
			a{
				color:@gray-base;
				&:hover{
					color:@orange;
					text-decoration: none;
				}
			}
			a:before{
				display:none;
			}
			li.expanded{
				a.nolink{
					font-family: @font-family-bold;
					text-transform: uppercase;
					font-size:@font-size-h3;
					color:@black;
					&:hover{
						color:@black;
					}
				}
			}
		}
	}
}



///
///USER
///

/* gérer mon profil */

#user-profile-form{
	.form-type-password-confirm{
		.form-type-password{
			span.glyphicon{
				display:none;
			}
			label.control-label{
				width:100%;

				.label{
					display:inline;
					float: right;
    				margin-top: 5px;
				}
			}
			.progress{
				margin-top:15px;
			}
		}
	}
	.form-actions{
		text-align:center;
		button#edit-submit{
			span.glyphicon{
				display:none;
			}
		}
	}
	.form-type-password-confirm.form-item-pass.form-item.form-group .help-block{
		.make-sm-column(4);
	}
}



.pane-fieldable-panels-pane ul.nav-tabs{
	border:none;
}


/* trouver une offre - select shs */

.shs-select{
	border: medium none;
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset, 0 -1px 0 rgba(0, 0, 0, 0.01) inset;
    color: #5c5747;
    display: block;
    font-size: 15px;
    height: 35px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    margin-bottom: 10px;
}

.field-type-taxonomy-term-reference{
	.form-group{
	   margin-bottom: 0;
	}
}

.page-user-poster-offre,
.page-poster-offre{
	.field-type-taxonomy-term-reference{
    .form-group{
       margin-bottom: inherit;
    }
}
}

.node-ads_entreprise-form{
	.form-item-title{
	   padding: 0 15px;
	}
}


/* gérer ses offres - entreprise */

.page-user,
.page-je-recrute-offres{
	table.table{
		margin-top:15px;
		td.active{
			background:none;
		}
		td{
			vertical-align: middle;
			a{
				span.fa{
					font-size:@font-size-h3;
				}
			}
			a:last-child{
				span.fa{
					margin-left:15px;
				}
			}
		}
		thead{
			th, a{
				color:@black;
				font-size:@font-size-base;
			}
			th img{
				float:right;
			}
			th.active{
				background:none;
			}
		}
		tbody, thead{
			tr{
				@media(max-width:@screen-sm-max){
					td:nth-child(5), th:nth-child(5), td:nth-child(6), th:nth-child(6), td:nth-child(7), th:nth-child(7){
						display: none;
					}
				}
				@media(max-width:@screen-xs-max){
					td:nth-child(1), th:nth-child(1), td:nth-child(4), th:nth-child(4), td:nth-child(5), th:nth-child(5), td:nth-child(6), th:nth-child(6), td:nth-child(7), th:nth-child(7){
						display: none;
					}
				}
			}
		}
		tbody{
			.dropdown{
				li{
					a{
						text-shadow: none;
						color:@black;
						&.dropdown-toggle{
							color: @white;
							padding: 7px 15px 0 15px;
							&:hover, &:focus{
								color:@white;
							}
						}
					}
				}
				.dropdown-menu{
					li a{
						padding: 5px 20px;
						&:hover, &:focus{
							background:@gray-light;
						color:@orange;
						}
					}
				}
			}
			.dropdown.open {
				.dropdown-toggle.btn-primary, .dropdown-toggle.btn-primary:hover, .dropdown-toggle.btn-primary:focus{
					background:@orange;
					border-color:@orange
				}
			}
		}
	}
}


/* Gérer ses candidats */

.page-user-candidatures-relation{
	.er-entreprise{
		label{
			font-family: "thesans-bold";
			font-size: 20px;
			text-transform: uppercase;
		}
		p{
			margin-bottom:15px;
		}
	}
}

/* tableau */

.unpublish{
	td{
	   color: @gray-dark;

	   a{
	       color: @gray-dark;
	   }
	}
}

.page-user-partenaire-register{
    .form-item-profile-ads-profile-partenaire-field-profile-email-und-0-email{
        display: none;
    }
}

@media print {

    * {
        -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
    }

     #navbar{
        display: block !important;
        margin: 0;
        padding: 0;

    }

    #navbar a[href]:after {
        content: none;
    }

    .menu-collapse {
        display: none !important;
    }

    .navbar-brand {
        display: block !important;
    }

    .logo {
        display: block !important;
        background: #f35e28 !important;
    }


    .logo img{
        display: block !important;

    }

    .site-name .hidden-xs {
        display: block !important;
    }

    .site-title {
        font-size: 34px !important;
        margin-bottom: 30px !important;
    }

    .lead {
        display: table;
        margin-top: 15px !important;
        padding-top: 5px;
    }

    .site-name {
        display: block !important;
        height: 135px !important;
        padding-top: 35px;
    }

    .mon-espace {
        display: none !important;
    }

    .node-ads-actualite blockquote {
        font-size: 14px !important;
        color: red !important;
    }

    .node-ads-actualite p {
        font-size: 12px !important;
    }
}



/* modal */
#ads-popup-message{

  .modal-dialog{
    z-index: 1050;
    width: 700px;
  }

  .modal-content{
    border-radius: 0;
    box-shadow: none;
    border: 0 none;
    padding: 30px 20px 30px 50px;

    h1{
      color: @black;
      font-family: Lato, sans-serif;
      font-size: @font-size-h1;

      span{
        color: @white;
      }
    }

    .close{
      //background: url("@{images-path}close.png") no-repeat 0 0;
      bottom: 0;
      top: 20px;
      width: 40px;
      height: 40px;
      position: absolute;
      right: 8px;
      opacity: 1;
    }

    .modal-header{
      padding: 0;
      border-bottom: 0 none;
      min-height: 0;
    }

    .modal-body{
      padding: 0;
      position: static;
    }
  }

  @media(max-width:@screen-md-min){
    .modal-dialog{
      width: 95%;
    }
    .modal-body img{
      max-width : 100%;
      heigth: auto;
      display: block;
    }
  }
}

#popup-buttons {
	height: 30px;
	button {
	  border-radius: 12px !important;
	}
}

.adscom-cookie-withdraw-banner #popup-text a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}
